.Header__badw-logo {
  height: 1.7em;
}

.Header__lmu-logo {
  height: 2em;
  margin-bottom: 0.2em;
}

.Header__logo-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Header__title {
  font-size: 1em;
  display: flex;
  align-items: center;
}

.Header__title-main {
  display: inline-block;
  margin-right: 0.5em;
  text-align: right;
  font-variant: unicase;
  vertical-align: middle;
}

.Header__title-abbreviation {
  color: white;
  font-size: 3em;
  font-variant: unicase;
  padding: 0 0.1em;
  background-color: #0077be;
  vertical-align: middle;
  margin-right: 0.2em;
}

#navbar-container {
  width: 100%;
}

#menu-lines {
  padding: 0 40px;
}

.Header__info-banner {
  padding-left: 0;
  padding-right: 0;
  max-width: 1200px;
}
