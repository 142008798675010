
@mixin normal-font {
  font-style: normal;
  font-variant: normal; }

@mixin sumerian {
  &--SUMERIAN {
    @include normal-font;
    letter-spacing: 0.1em; } }

@mixin emesal {
  &--EMESAL {
    @include normal-font;
    color: black;
    letter-spacing: 0.1em; } }

@mixin commentary-protocols {
  &--commentary-protocol- {
    &bs {
      color: blue; }
    &qt {
      color: red; }
    &cm {
      color: black; }
    &zz {
      color: black; } } }

.Transliteration {
  &__lines tr {
    vertical-align: top; }

  &__notes {
    list-style: none;
    padding: 0; }

  &__ControlLine,
  &__TranslationLine,
  &__ParallelFragment,
  &__ParallelComposition,
  &__ParallelText {
    color: darkgrey; }

  .reference-popover__citation {
    .type-abbreviation {
      display: none; } }

  &__DollarAndAtLine,
  &__DollarAndAtLineWithParenthesis {
    color: darkgrey;
    margin-left: 1em; }

  &__ruling {
    margin-bottom: 0.2em;
    border-top: thin solid black; }

  &__Number,
  &__Divider,
  &__LiniBreak {
    @include normal-font; }

  &__EgyptianMetricalFeetSeparator {
    &--colored {
      color: red; } }

  &__Reading,
  &__UnidentifiedSign,
  &__Joiner,
  &__AkkadianWord {
    @include commentary-protocols;
    font-style: italic;
    font-variant: normal;

    .Transliteration__Determinative &,
    .Transliteration__Word--SUMERIAN &,
    .Transliteration__Word--EMESAL & {
      @include normal-font; } }

  &__bracket,
  &__flag,
  &__subIndex,
  &__modifier {
    @include normal-font;
    @include commentary-protocols; }

  &__glossJoiner {
    @include commentary-protocols;

    display: none; }

  &__Determinative {
    @include normal-font;

    & + & .Transliteration__glossJoiner {
      display: inline; } }

  &__Word,
  &__Divider {
    @include emesal;
    @include sumerian;
    @include commentary-protocols; }

  &__Logogram,
  &__CompoundGrapheme,
  &__Grapheme {
    font-style: normal;
    font-variant: all-small-caps; }

  &__Reading,
  &__Number,
  &__Logogram,
  &__Divider,
  &__Grapheme,
  &__CompoundGrapheme,
  &__UnidentifiedSign,
  &__UnclearSign,
  &__Joiner,
  &__glossJoiner,
  &__GreekLetter {
    @include commentary-protocols;

    &--BROKEN_AWAY {
      color: darkgrey; } }

  &__AkkadianWord {
    .Transliteration__ValueToken {
      @include commentary-protocols;

      &--BROKEN_AWAY {
        color: darkgrey; } } }

  &__Caesura,
  &__MetricalFootSeparator {
    @include normal-font;

    color: red;
    position: relative; }

  &__MetricalFootSeparator.hidden {
    display: none; }

  &__Caesura.hidden {
    visibility: hidden;

    & + .Transliteration__wordSeparator {
      display: none; } }

  &__BrokenAway,
  &__PerhapsBrokenAway,
  &__AccidentalOmission,
  &__IntentionalOmission,
  &__Removal,
  &__Emendation {
    @include normal-font;
    @include commentary-protocols; }

  &__UnknownNumberOfSigns {
    @include commentary-protocols; }

  &__Tabulation {
    display: inline-block;
    width: 1em;

    & + .Transliteration__wordSeparator {
      display: none; } }

  &__NoteLink {
    border: medium black solid;
    color: black;
    font-size: 0.8em;
    padding: 0.1em;
    vertical-align: super; }

  &__anchor {
    color: inherit; }

  &__inAlignSet {
    background-color: lightgrey; } }

.chapter-display__line {
  .Transliteration {
    &__AkkadianWord {
      display: inline-grid;
      grid-template-rows: 1fr;
      .meter-display {
        @include normal-font;
        color: red; }

      .ipa-display {
        @include normal-font;
        color: deepskyblue; } } } }
